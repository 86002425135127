<template>
  <div>
    <h3
      class="text-2xl text-dokBlue-ultra font-semibold border-0 border-b-2 border-solid pb-2 mb-4"
    >
      <i class="fas fa-heartbeat"></i> Antécédents Chirurgicaux
    </h3>

    <div class="flex flex-col w-full">
      <div class="flex justify-between mb-5">
        <div>
          <label>Avez-vous déjà opéré ? </label>
        </div>
        <p class="text-xs text-gray-500">
          (Saisissez le type et la date de l'opération puis cliquer sur
          <i class="fa fa-plus"></i> pour sauvegarder)
        </p>
      </div>
      <div class="flex justify-end" v-if="showOperationsBlock">
        <div class="flex flex-col w-full lg:w-1/2">
          <table class="table-auto">
            <thead>
              <tr>
                <th class="w-1/2">
                  <input
                    type="text"
                    v-model="operationName"
                    class="bg-white text-dokBlue-ultra appearance-none border border-solid inline-block py-2 pl-3 pr-8 rounded leading-none placeholder-gray-500 w-full"
                    placeholder="Nom de l'opération"
                    :class="error ? 'border-red-500' : 'border-dokBlue-ultra'"
                  />
                  <p v-if="error && errorMessage" class="text-red-400">
                    {{ errorMessage }}
                  </p>
                </th>
                <th class="w-5/12">
                  <datepicker
                    v-model="operationDate"
                    placeholder="Date de l'opération"
                    :language="dateLangFR"
                    class=""
                    :format="customFormatter"
                    input-class="bg-white  appearance-none border border-solid inline-block py-2 pl-3 pr-8 rounded leading-none placeholder-gray-500 w-full border-dokBlue-ultra"
                  ></datepicker>
                </th>
                <th style="width:35px;">
                  <button
                    v-if="saveAction"
                    @click="saveOperation"
                    class="bg-transparent border border-dokBlue-ultra py-2 px-2 rounded-cu text-dokBlue-ultra cursor-pointer"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <operation
                :patient="patient"
                v-for="(oper, index) in listOperations"
                :key="index"
                :operation="oper"
                v-on:operationSaved="updateListe()"
                v-on:operationRemoved="removeFromList"
              ></operation>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const toggleInput = () => import("@/views/global-components/toggle");
const Operation = () => import("./operation");
import { remove } from "lodash";
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import moment from "moment";

export default {
  props: ["patient"],
  components: { Operation, toggleInput, Datepicker },
  data() {
    return {
      dateLangFR: fr,
      error: "",
      operationName: "",
      operationDate: "",
      listOperations: [],
      showOperationsBlock: true,
      saveAction: false
    };
  },

  mounted() {
    var el = this;
    this.$store.dispatch("healthbook/GET_ANTECEDENT_CHIRURGICAUX_LIST", {
      patientId: this.patient._id,
      onData: this.onData
    });
  },
  watch: {
    operationName: function(newVal) {
      if (newVal.length > 0) {
        this.saveAction = true;
      } else {
        this.saveAction = false;
      }
    }
    /*showOperationsBlock: function(newVal) {
      const el = this
      if(!newVal && this.listOperations.length > 1 && this.listOperations[0].operation) {
        var vonf = confirm("En désactivant vous risquer de tout supprimer. \nVoulez-vous vraiement confirmer cette action ?")
        if(vonf) {
          this.$store
          .dispatch("healthbook/REMOVE_ALL_PATIENT_ANTECEDENT_CHIRURGICAUX", {
            patientId: this.patient._id,
            onData:function({ok}) {
              if(ok) {
                el.listOperations = [{operation:'', operationDate:null}]
              }
              el.$forceUpdate();
            }
          });
        } else {
          setTimeout(function(){
            el.showOperationsBlock = true
          }, 200);
        }

      }
    },*/
  },

  methods: {
    saveOperation() {
      this.error = false;
      this.errorMessage = "";
      if (!this.operationName || !this.operationName.trim()) {
        this.error = true;
        this.errorMessage = "Ce champ est obligatoire";
        return;
      }

      // this.operation.add = true
      this.$store.dispatch("healthbook/SAVE_PATIENT_ANTECEDENT_CHIRURGICAL", {
        patientId: this.patient._id,
        data: {
          operation: this.operationName,
          operationDate: this.operationDate
        },
        onData: this.onDataSave
      });
    },
    onDataSave(data) {
      if (data.ok) {
        this.listOperations.push(data.data);
        this.operationName = "";
        this.operationDate = "";
      }
      this.$forceUpdate();

      // this.updateListe()
    },
    onData(response) {
      this.listOperations = response.data;
      // this.showOperationsBlock = this.listOperations.length > 0
      // this.listOperations.push({operation:'', operationDate:null})
      this.$forceUpdate();
    },
    removeFromList(oper) {
      remove(this.listOperations, function(o) {
        return o._id && o._id == oper._id;
      });
      this.$forceUpdate();
    },
    updateListe() {
      // this.listOperations.push({operation:'', operationDate:null})
    },
    moment() {
      return moment;
    },
    customFormatter(date) {
      this.operationDate = moment(date).format("YYYY-MM-DD");
      return moment(date).format("DD MMM YYYY");
    }
  }
};
</script>
